import { default as about_45us9kiw2OpfgnMeta } from "/opt/build/repo/pages/about-us.vue?macro=true";
import { default as _91slug_932BH3UhQjmqMeta } from "/opt/build/repo/pages/blog/[slug].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as _91planId_934tCJOGeDAQMeta } from "/opt/build/repo/pages/checkout/[planId].vue?macro=true";
import { default as thank_45youbo5aY4j4EWMeta } from "/opt/build/repo/pages/checkout/thank-you.vue?macro=true";
import { default as contact_45usM8CpKpYLVzMeta } from "/opt/build/repo/pages/contact-us.vue?macro=true";
import { default as _91_91pageId_93_93sKbwag7UKlMeta } from "/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue?macro=true";
import { default as deletion_45startedCUnx0ZVM0uMeta } from "/opt/build/repo/pages/dashboard/deletion-started.vue?macro=true";
import { default as indexViWjQzLqkmMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as search_45and_45deletenq81tSJlUdMeta } from "/opt/build/repo/pages/dashboard/search-and-delete.vue?macro=true";
import { default as see_45deleted_45tweetsbhSAFWhSL4Meta } from "/opt/build/repo/pages/dashboard/see-deleted-tweets.vue?macro=true";
import { default as _91_91ruleId_93_93rqsEluFXKoMeta } from "/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue?macro=true";
import { default as tasksXoPRaHtXwPMeta } from "/opt/build/repo/pages/dashboard/tasks.vue?macro=true";
import { default as faqKBZt61hnJ8Meta } from "/opt/build/repo/pages/faq.vue?macro=true";
import { default as _91featureSlug_93jYf3ubGh5aMeta } from "/opt/build/repo/pages/features/[featureSlug].vue?macro=true";
import { default as indexKrctpGCWF5Meta } from "/opt/build/repo/pages/features/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as pricing2lLggLVj9TMeta } from "/opt/build/repo/pages/pricing.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as terms_45of_45serviceIo0hlUNnc4Meta } from "/opt/build/repo/pages/terms-of-service.vue?macro=true";
import { default as _91_91pageId_93_935Ac5F7wB8xMeta } from "/opt/build/repo/pages/upload-archive/[[pageId]].vue?macro=true";
import { default as component_45stubVlEI75ehPBMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubVlEI75ehPB } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___en",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/about-us",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___es",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/es/conocenos",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___ja",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/ja/tweetdeleter-ni-tsuite",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___pt",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/pt/sobre-nos",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___ar",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/ar/nabtha-aana",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___fr",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/fr/qui-nous-sommes",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___de",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/de/ueber-uns",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___tr",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/tr/hakkimizda",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___hi",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/hi/hamaare-baare-mein",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___ko",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/ko/ulie-daehae",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___ru",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/ru/o-nas",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___tl",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/tl/tungkol-sa-amin",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___th",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/th/keiyw-kab-rea",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us___ms",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/ms/perihal-kami",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___en",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___es",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/es/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___ja",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/ja/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___pt",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/pt/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___ar",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/ar/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___fr",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___de",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___tr",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/tr/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___hi",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/hi/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___ko",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/ko/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___ru",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/ru/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___tl",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/tl/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___th",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/th/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___ms",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/ms/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___en",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___es",
    path: indexmFsYN4t0XfMeta?.path ?? "/es/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___ja",
    path: indexmFsYN4t0XfMeta?.path ?? "/ja/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___pt",
    path: indexmFsYN4t0XfMeta?.path ?? "/pt/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___ar",
    path: indexmFsYN4t0XfMeta?.path ?? "/ar/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___fr",
    path: indexmFsYN4t0XfMeta?.path ?? "/fr/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___de",
    path: indexmFsYN4t0XfMeta?.path ?? "/de/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___tr",
    path: indexmFsYN4t0XfMeta?.path ?? "/tr/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___hi",
    path: indexmFsYN4t0XfMeta?.path ?? "/hi/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___ko",
    path: indexmFsYN4t0XfMeta?.path ?? "/ko/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___ru",
    path: indexmFsYN4t0XfMeta?.path ?? "/ru/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___tl",
    path: indexmFsYN4t0XfMeta?.path ?? "/tl/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___th",
    path: indexmFsYN4t0XfMeta?.path ?? "/th/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___ms",
    path: indexmFsYN4t0XfMeta?.path ?? "/ms/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___en",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___es",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/es/comprar/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___ja",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/ja/chekkuauto/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___pt",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/pt/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___ar",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/ar/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___fr",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/fr/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___de",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/de/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___tr",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/tr/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___hi",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/hi/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___ko",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/ko/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___ru",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/ru/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___tl",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/tl/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___th",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/th/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: _91planId_934tCJOGeDAQMeta?.name ?? "checkout-planId___ms",
    path: _91planId_934tCJOGeDAQMeta?.path ?? "/ms/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    alias: _91planId_934tCJOGeDAQMeta?.alias || [],
    redirect: _91planId_934tCJOGeDAQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___en",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___es",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/es/comprar/gracias",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___ja",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/ja/chekkuauto/arigatogozaimasu",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___pt",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/pt/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___ar",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/ar/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___fr",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/fr/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___de",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/de/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___tr",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/tr/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___hi",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/hi/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___ko",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/ko/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___ru",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/ru/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___tl",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/tl/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___th",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/th/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youbo5aY4j4EWMeta?.name ?? "checkout-thank-you___ms",
    path: thank_45youbo5aY4j4EWMeta?.path ?? "/ms/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    alias: thank_45youbo5aY4j4EWMeta?.alias || [],
    redirect: thank_45youbo5aY4j4EWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___en",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/contact-us",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___es",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/es/contacto",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___ja",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/ja/o-toiawase",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___pt",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/pt/fala-connosco",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___ar",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/ar/etasil-bina",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___fr",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/fr/nous-contacter",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___de",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/de/kontakt",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___tr",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/tr/iletisim",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___hi",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/hi/hamase-sampark-karen",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___ko",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/ko/mun-uihagi",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___ru",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/ru/obratitsya-k-nam",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___tl",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/tl/kontakin-kami",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___th",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/th/tidtx-rea",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us___ms",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/ms/hubungi-kami",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___en",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___es",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/es/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___ja",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/ja/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___pt",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/pt/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___ar",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/ar/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___fr",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/fr/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___de",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/de/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___tr",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/tr/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___hi",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/hi/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___ko",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/ko/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___ru",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/ru/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___tl",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/tl/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___th",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/th/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_93sKbwag7UKlMeta?.name ?? "dashboard-delete-all-pageId___ms",
    path: _91_91pageId_93_93sKbwag7UKlMeta?.path ?? "/ms/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    alias: _91_91pageId_93_93sKbwag7UKlMeta?.alias || [],
    redirect: _91_91pageId_93_93sKbwag7UKlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___en",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___es",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/es/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___ja",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/ja/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___pt",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/pt/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___ar",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/ar/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___fr",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/fr/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___de",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/de/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___tr",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/tr/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___hi",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/hi/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___ko",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/ko/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___ru",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/ru/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___tl",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/tl/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___th",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/th/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: deletion_45startedCUnx0ZVM0uMeta?.name ?? "dashboard-deletion-started___ms",
    path: deletion_45startedCUnx0ZVM0uMeta?.path ?? "/ms/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    alias: deletion_45startedCUnx0ZVM0uMeta?.alias || [],
    redirect: deletion_45startedCUnx0ZVM0uMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___en",
    path: indexViWjQzLqkmMeta?.path ?? "/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___es",
    path: indexViWjQzLqkmMeta?.path ?? "/es/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___ja",
    path: indexViWjQzLqkmMeta?.path ?? "/ja/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___pt",
    path: indexViWjQzLqkmMeta?.path ?? "/pt/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___ar",
    path: indexViWjQzLqkmMeta?.path ?? "/ar/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___fr",
    path: indexViWjQzLqkmMeta?.path ?? "/fr/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___de",
    path: indexViWjQzLqkmMeta?.path ?? "/de/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___tr",
    path: indexViWjQzLqkmMeta?.path ?? "/tr/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___hi",
    path: indexViWjQzLqkmMeta?.path ?? "/hi/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___ko",
    path: indexViWjQzLqkmMeta?.path ?? "/ko/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___ru",
    path: indexViWjQzLqkmMeta?.path ?? "/ru/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___tl",
    path: indexViWjQzLqkmMeta?.path ?? "/tl/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___th",
    path: indexViWjQzLqkmMeta?.path ?? "/th/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard___ms",
    path: indexViWjQzLqkmMeta?.path ?? "/ms/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___en",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___es",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/es/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___ja",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/ja/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___pt",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/pt/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___ar",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/ar/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___fr",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/fr/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___de",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/de/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___tr",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/tr/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___hi",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/hi/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___ko",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/ko/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___ru",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/ru/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___tl",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/tl/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___th",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/th/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45deletenq81tSJlUdMeta?.name ?? "dashboard-search-and-delete___ms",
    path: search_45and_45deletenq81tSJlUdMeta?.path ?? "/ms/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    alias: search_45and_45deletenq81tSJlUdMeta?.alias || [],
    redirect: search_45and_45deletenq81tSJlUdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___en",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___es",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/es/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___ja",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/ja/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___pt",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/pt/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___ar",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/ar/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___fr",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/fr/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___de",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/de/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___tr",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/tr/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___hi",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/hi/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___ko",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/ko/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___ru",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/ru/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___tl",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/tl/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___th",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/th/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: see_45deleted_45tweetsbhSAFWhSL4Meta?.name ?? "dashboard-see-deleted-tweets___ms",
    path: see_45deleted_45tweetsbhSAFWhSL4Meta?.path ?? "/ms/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    alias: see_45deleted_45tweetsbhSAFWhSL4Meta?.alias || [],
    redirect: see_45deleted_45tweetsbhSAFWhSL4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___en",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___es",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/es/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___ja",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/ja/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___pt",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/pt/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___ar",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/ar/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___fr",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/fr/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___de",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/de/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___tr",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/tr/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___hi",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/hi/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___ko",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/ko/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___ru",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/ru/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___tl",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/tl/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___th",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/th/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91ruleId_93_93rqsEluFXKoMeta?.name ?? "dashboard-set-up-auto-pageId-ruleId___ms",
    path: _91_91ruleId_93_93rqsEluFXKoMeta?.path ?? "/ms/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    alias: _91_91ruleId_93_93rqsEluFXKoMeta?.alias || [],
    redirect: _91_91ruleId_93_93rqsEluFXKoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___en",
    path: tasksXoPRaHtXwPMeta?.path ?? "/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___es",
    path: tasksXoPRaHtXwPMeta?.path ?? "/es/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___ja",
    path: tasksXoPRaHtXwPMeta?.path ?? "/ja/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___pt",
    path: tasksXoPRaHtXwPMeta?.path ?? "/pt/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___ar",
    path: tasksXoPRaHtXwPMeta?.path ?? "/ar/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___fr",
    path: tasksXoPRaHtXwPMeta?.path ?? "/fr/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___de",
    path: tasksXoPRaHtXwPMeta?.path ?? "/de/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___tr",
    path: tasksXoPRaHtXwPMeta?.path ?? "/tr/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___hi",
    path: tasksXoPRaHtXwPMeta?.path ?? "/hi/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___ko",
    path: tasksXoPRaHtXwPMeta?.path ?? "/ko/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___ru",
    path: tasksXoPRaHtXwPMeta?.path ?? "/ru/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___tl",
    path: tasksXoPRaHtXwPMeta?.path ?? "/tl/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___th",
    path: tasksXoPRaHtXwPMeta?.path ?? "/th/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: tasksXoPRaHtXwPMeta?.name ?? "dashboard-tasks___ms",
    path: tasksXoPRaHtXwPMeta?.path ?? "/ms/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    alias: tasksXoPRaHtXwPMeta?.alias || [],
    redirect: tasksXoPRaHtXwPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___en",
    path: faqKBZt61hnJ8Meta?.path ?? "/faq",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___es",
    path: faqKBZt61hnJ8Meta?.path ?? "/es/preguntas-frecuentes",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___ja",
    path: faqKBZt61hnJ8Meta?.path ?? "/ja/yoku-aru-go-shitsumon",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___pt",
    path: faqKBZt61hnJ8Meta?.path ?? "/pt/perguntas-frequentes",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___ar",
    path: faqKBZt61hnJ8Meta?.path ?? "/ar/al-asila-al-mutadawala",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___fr",
    path: faqKBZt61hnJ8Meta?.path ?? "/fr/faq",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___de",
    path: faqKBZt61hnJ8Meta?.path ?? "/de/haeufig-gestellte-fragen",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___tr",
    path: faqKBZt61hnJ8Meta?.path ?? "/tr/sss",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___hi",
    path: faqKBZt61hnJ8Meta?.path ?? "/hi/praay-poochhe-jaane-vaale-prashna",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___ko",
    path: faqKBZt61hnJ8Meta?.path ?? "/ko/jaju-mudneun-jilmun",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___ru",
    path: faqKBZt61hnJ8Meta?.path ?? "/ru/voprosi-i-otveti",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___tl",
    path: faqKBZt61hnJ8Meta?.path ?? "/tl/faq",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___th",
    path: faqKBZt61hnJ8Meta?.path ?? "/th/khatham-thi-phb-bxy",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___ms",
    path: faqKBZt61hnJ8Meta?.path ?? "/ms/soalan-lazim",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___en",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/features/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___es",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/es/funciones/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___ja",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/ja/kino/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___pt",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/pt/funcionalidades/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___ar",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/ar/almizat/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___fr",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/fr/fonctionnalites/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___de",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/de/funktionen/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___tr",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/tr/ozellikler/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___hi",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/hi/suvidhaen/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___ko",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/ko/gineung/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___ru",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/ru/funkcii/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___tl",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/tl/mga-feature/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___th",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/th/khunsmbati/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: _91featureSlug_93jYf3ubGh5aMeta?.name ?? "features-featureSlug___ms",
    path: _91featureSlug_93jYf3ubGh5aMeta?.path ?? "/ms/ciri-ciri/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    alias: _91featureSlug_93jYf3ubGh5aMeta?.alias || [],
    redirect: _91featureSlug_93jYf3ubGh5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___en",
    path: indexKrctpGCWF5Meta?.path ?? "/features",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___es",
    path: indexKrctpGCWF5Meta?.path ?? "/es/funciones",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___ja",
    path: indexKrctpGCWF5Meta?.path ?? "/ja/kino",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___pt",
    path: indexKrctpGCWF5Meta?.path ?? "/pt/funcionalidades",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___ar",
    path: indexKrctpGCWF5Meta?.path ?? "/ar/al-mayeezat",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___fr",
    path: indexKrctpGCWF5Meta?.path ?? "/fr/fonctionnalites",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___de",
    path: indexKrctpGCWF5Meta?.path ?? "/de/funktionen",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___tr",
    path: indexKrctpGCWF5Meta?.path ?? "/tr/ozellikler",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___hi",
    path: indexKrctpGCWF5Meta?.path ?? "/hi/suvidhaen",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___ko",
    path: indexKrctpGCWF5Meta?.path ?? "/ko/gineung",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___ru",
    path: indexKrctpGCWF5Meta?.path ?? "/ru/funkcii",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___tl",
    path: indexKrctpGCWF5Meta?.path ?? "/tl/mga-feature",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___th",
    path: indexKrctpGCWF5Meta?.path ?? "/th/khunsmbati",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrctpGCWF5Meta?.name ?? "features___ms",
    path: indexKrctpGCWF5Meta?.path ?? "/ms/ciri-ciri",
    meta: indexKrctpGCWF5Meta || {},
    alias: indexKrctpGCWF5Meta?.alias || [],
    redirect: indexKrctpGCWF5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___es",
    path: indexlaFeZuYZkUMeta?.path ?? "/es",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ja",
    path: indexlaFeZuYZkUMeta?.path ?? "/ja",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___pt",
    path: indexlaFeZuYZkUMeta?.path ?? "/pt",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ar",
    path: indexlaFeZuYZkUMeta?.path ?? "/ar",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___fr",
    path: indexlaFeZuYZkUMeta?.path ?? "/fr",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___de",
    path: indexlaFeZuYZkUMeta?.path ?? "/de",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___tr",
    path: indexlaFeZuYZkUMeta?.path ?? "/tr",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___hi",
    path: indexlaFeZuYZkUMeta?.path ?? "/hi",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ko",
    path: indexlaFeZuYZkUMeta?.path ?? "/ko",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ru",
    path: indexlaFeZuYZkUMeta?.path ?? "/ru",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___tl",
    path: indexlaFeZuYZkUMeta?.path ?? "/tl",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___th",
    path: indexlaFeZuYZkUMeta?.path ?? "/th",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ms",
    path: indexlaFeZuYZkUMeta?.path ?? "/ms",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___en",
    path: pricing2lLggLVj9TMeta?.path ?? "/pricing",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___es",
    path: pricing2lLggLVj9TMeta?.path ?? "/es/precios",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___ja",
    path: pricing2lLggLVj9TMeta?.path ?? "/ja/ryokin-puran",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___pt",
    path: pricing2lLggLVj9TMeta?.path ?? "/pt/preco",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___ar",
    path: pricing2lLggLVj9TMeta?.path ?? "/ar/al-asaar",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___fr",
    path: pricing2lLggLVj9TMeta?.path ?? "/fr/prix",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___de",
    path: pricing2lLggLVj9TMeta?.path ?? "/de/preise",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___tr",
    path: pricing2lLggLVj9TMeta?.path ?? "/tr/fiyatlandirma",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___hi",
    path: pricing2lLggLVj9TMeta?.path ?? "/hi/keemat",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___ko",
    path: pricing2lLggLVj9TMeta?.path ?? "/ko/hoebi",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___ru",
    path: pricing2lLggLVj9TMeta?.path ?? "/ru/ceni",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___tl",
    path: pricing2lLggLVj9TMeta?.path ?? "/tl/presyo",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___th",
    path: pricing2lLggLVj9TMeta?.path ?? "/th/rakha",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___ms",
    path: pricing2lLggLVj9TMeta?.path ?? "/ms/harga",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___en",
    path: profileNJNbyWXx9rMeta?.path ?? "/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___es",
    path: profileNJNbyWXx9rMeta?.path ?? "/es/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___ja",
    path: profileNJNbyWXx9rMeta?.path ?? "/ja/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___pt",
    path: profileNJNbyWXx9rMeta?.path ?? "/pt/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___ar",
    path: profileNJNbyWXx9rMeta?.path ?? "/ar/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___fr",
    path: profileNJNbyWXx9rMeta?.path ?? "/fr/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___de",
    path: profileNJNbyWXx9rMeta?.path ?? "/de/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___tr",
    path: profileNJNbyWXx9rMeta?.path ?? "/tr/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___hi",
    path: profileNJNbyWXx9rMeta?.path ?? "/hi/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___ko",
    path: profileNJNbyWXx9rMeta?.path ?? "/ko/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___ru",
    path: profileNJNbyWXx9rMeta?.path ?? "/ru/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___tl",
    path: profileNJNbyWXx9rMeta?.path ?? "/tl/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___th",
    path: profileNJNbyWXx9rMeta?.path ?? "/th/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile___ms",
    path: profileNJNbyWXx9rMeta?.path ?? "/ms/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___en",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___es",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/es/condiciones-del-servicio",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___ja",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/ja/sabisu-riyo-kiyaku",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___pt",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/pt/termos-do-servico",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___ar",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/ar/shuroot-al-istikhdam",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___fr",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/fr/conditions-dutilisation",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___de",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/de/nutzungsbedingungen",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___tr",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/tr/kullanim-sartlari",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___hi",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/hi/seva-kee-sharten",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___ko",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/ko/seobiseu-yaggwan",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___ru",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/ru/usloviya-ispolzovaniya",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___tl",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/tl/mga-tuntunin-ng-serbisyo",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___th",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/th/ngeuxnkhi-kar-h%C4%B1brikar",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service___ms",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/ms/terma-perkhidmatan",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___en",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___es",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/es/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___ja",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/ja/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___pt",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/pt/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___ar",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/ar/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___fr",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/fr/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___de",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/de/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___tr",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/tr/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___hi",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/hi/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___ko",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/ko/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___ru",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/ru/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___tl",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/tl/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___th",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/th/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageId_93_935Ac5F7wB8xMeta?.name ?? "upload-archive-pageId___ms",
    path: _91_91pageId_93_935Ac5F7wB8xMeta?.path ?? "/ms/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    alias: _91_91pageId_93_935Ac5F7wB8xMeta?.alias || [],
    redirect: _91_91pageId_93_935Ac5F7wB8xMeta?.redirect,
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue").then(m => m.default || m)
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/about",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/about/faq",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/tweets",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/tweets/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/about/terms-of-service",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/archives",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/auto_delete",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/blog",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/blog/21",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/blog/21/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/blog/18",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/blog/18/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/2",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/2/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/3",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/3/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/4",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/4/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/5",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/5/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/9",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/9/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/features/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/offers",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/offers/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/en/",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/about",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/about/faq",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/about/terms-of-service",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/archives",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/auto_delete",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/blog/21",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/blog/21/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/blog/18",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/blog/18/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/2",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/2/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/3",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/3/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/4",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/4/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/5",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/5/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/9",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/9/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/features/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/offers",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/home",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/HVZ",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/ja/messages/new",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/about",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/about/faq",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/about/terms-of-service",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/archives",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/auto_delete",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/blog/21",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/blog/21/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/blog/18",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/blog/18/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/2",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/2/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/3",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/3/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/4",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/4/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/5",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/5/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/9",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/9/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/features/:pathMatch(.*)",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/offers",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/home",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/HVZ",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/es/messages/new",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/blog/managing-sensitive-content-on-x-twitter",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/blog/exploring-twitter-without-an-account-access-public-x",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  }
]