export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"og:site_name","content":"TweetDeleter"},{"property":"og:type","content":"website"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"@TweetDeleter1"},{"name":"twitter:creator","content":"@TweetDeleter1"},{"name":"msapplication-TileColor","content":"#1DA1F2"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"preconnect","href":"https://staging-backend.tweetdeleter.com"},{"rel":"preconnect","href":"https://fonts.googleapis.com","crossorigin":"anonymous"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/img/favicon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/img/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/img/favicon/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest","crossorigin":"use-credentials"},{"rel":"preload","as":"style","href":"https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap","media":"print","onload":"this.media='all'"}],"style":[],"script":[{"id":"cookieyes","key":"cookieyes","type":"text/javascript","src":"https://cdn-cookieyes.com/client_data/9bdb02142969179db8728f04/script.js","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false